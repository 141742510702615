//this scss file contains the necessary style for menu style

/*****************************
	--- MENU
*****************************/
.mainmenu-wrapper{
  position: relative;
  z-index: 3;
}

.menu-area{
  .top-menu-area{
    .logo-top{
      text-align: center;
      a{
        display: block;
        padding: 19px 35px 19px 20px;
      }
    }
  }
  .mainmenu__menu .navbar-nav > li{
    &:hover{
      >a{
        @extend .color-primary;
      }
    }
    &.has_dropdown{
      position: relative;
      .dropdown-toggle{
        &:after{
          content: '\f110';
          font-family: "LineAwesome";
          font-size: remy(10px);
          padding-left: 3px;
        }
      }
      &:hover{
        .dropdown-menu{
          visibility: visible;
          opacity: 1;
        }
      }
      .dropdown-menu{
        display: block;
        padding:remy(15px) 0;
        min-width: remy(200px);
        border-radius: 0 0 3px 3px;
        visibility: hidden;
        opacity: 0;
        transition: $transition-base;
        z-index: 10;
        border: 0 none;
        box-shadow: 0 5px 15px rgba($dark, 0.1);
        top: 98%;
        a{
          display: block;
          padding: remy(8px) remy(25px);
        }
        li:hover a{
          @extend .color-primary;
        }
        .dropdown-menu--inner{
          position: relative;
          & > a{
            &:after{
              content: '\f110';
              font-family: "LineAwesome";
              font-size: remy(10px);
              padding-left: 0;
              right: remy(15px);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          & > ul{
            position: absolute;
            left: 100%;
            top: 0;
            width: remy(160px);
            visibility: hidden;
            opacity: 0;
            transition: $transition-base;
            padding:remy(7px) 0;
            box-shadow: 0 5px 15px rgba($dark, 0.1);
            li{
              a{
                display: block;
                background: none;
                &:hover{
                  @extend .color-primary;
                }
              }
            }
          }
          &:hover{
            & > ul{
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .author__notification_area ul li .notification_count.purch{
    @extend .bg-secondary;
  }
  .author__notification_area{
    ul li{
      padding: 30px 0;
    }
  }
  .navbar-toggler{
    border: 1px solid;
    padding: 3px;
    border-radius: 2px;
    outline: 0;
    .icon-menu{
      font-size: remy(20px);
      display: inline-block;
      line-height: normal;
      margin-bottom: -3px;
    }
  }
  &.menu--light{
    .top-menu-area{
      background: transparent;
      border-bottom: 1px solid rgba(#fff, 0.1);
      .logo-wrapper{
        border-color: rgba(#fff, 0.15);
      }
    }
    .mainmenu__menu .navbar-nav > li{
      > a{
        color: #fff;
      }
      &.has_dropdown{
        .dropdown-menu{
          a{
            color: $text-grey1;
          }
          .dropdown-menu--inner{
            & > ul{
              background: #fff;
              li{
                a{
                  color: $text-grey1;
                  &:hover{
                    @extend .color-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-form button,
    .author__notification_area ul li span:first-child{
      color: rgba(255,255,255,0.8);
    }
    .author__notification_area{
      border-color: rgba(#fff, 0.1);
    }
    .search-wrapper{
      .search_module{
        .icon-left{
          color: #fff;
        }
        .search_area{
          form{
            .input-group{
              .form-control{
                color: #fff;
                &::placeholder{
                  color: rgba(#fff, 0.5);
                }
              }
            }
          }
        }
        &.active{
          .icon-left{
            color: $dark;
          }
          .search_area{
            form .input-group .form-control{
              border-color: rgba($dark, 0.1);
              background: #fff;
              color: $dark;
              &::placeholder{
                color: $light-gray;
              }
            }
          }
        }
      }
    }
    .author__access_area ul li .access-link{
      color: rgba(#fff, 0.8);
    }
    .author__access_area ul li > span{
      color: rgba(#fff, 0.5);
    }
    .navbar-toggler{
      border-color: rgba(#fff, 0.7);
      .icon-menu{
        color: rgba(#fff, 0.7);
      }
    }
  }
  &.menu--dark{
    .top-menu-area{
      background: transparent;
      border-bottom: 0 none;
      box-shadow: 0 5px remy(15px) rgba($dark, 0.05);
      .logo-wrapper{
        border-color: rgba($dark, 0.15);
      }
    }
    .mainmenu__menu .navbar-nav > li{
      > a{
        color: $dark;
      }
      &.has_dropdown{
        .dropdown-menu{
          a{
            color: $text-grey1;
          }
          .dropdown-menu--inner{
            & > ul{
              background: #fff;
              li{
                a{
                  color: $text-grey1;
                }
              }
            }
          }
        }
      }
    }
    .search-form button,
    .author__notification_area ul li span:first-child{
      color: rgba(255,255,255,0.8);
    }
    .author__notification_area{
      border-color: rgba(#fff, 0.1);
    }

    .search-wrapper{
      .search_module{
        .icon-left{
          color: $dark;
        }
        .search_area{
          form{
            .input-group{
              .form-control{
                color: $dark;
                &::placeholder{
                  color: rgba($dark, 0.5);
                }
              }
            }
          }
        }
        &.active{
          .icon-left{
            color: $dark;
          }
          .search_area{
            form .input-group .form-control{
              border-color: rgba($dark, 0.1);
              background: #fff;
              color: $dark;
              &::placeholder{
                color: $light-gray;
              }
            }
          }
        }
      }
    }
    .author__access_area ul li .access-link{
      color: $dark;
    }
    .author__access_area ul li > span{
      color: rgba($dark, 0.5);
    }
    .navbar-toggler{
      border-color: rgba($dark, 0.7);
      .icon-menu{
        color: rgba($dark, 0.7);
      }
    }
  }
}


/*****************************
	START MENU AREA
*****************************/
.top-menu-area {
  background: #fff;
  border-bottom: 1px solid $border-color;
  .menu-fullwidth {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .logo-wrapper{
      border-right: 1px solid;
    }
    .menu-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.search-wrapper{
  margin-right: remy(30px);
  position: relative;
  min-width: remy(350px);
  .search_module{
    position: relative;
    .icon-left{
      position: absolute;
      left: remy(10px);
      top: 50%;
      transform: translateY(-50%);
      font-size: remy(18px);
      transition: $transition-base;
      z-index: 10;
    }
    .search_area{
      form{
        .input-group{
          margin: 0;
          .form-control{
            background: none;
            padding-left: remy(40px);
            border: 1px solid transparent;
            transition: $transition-base;
            width: 100%;
            &::placeholder{
              transition: $transition-base;
            }
            &:focus{
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }
    }
    &.active{
      & + .search-categories{
        visibility: visible;
        opacity: 1;
      }
      .search_area{
        form .input-group .form-control{
          border: 1px solid;
          border-radius: 3px 3px 0 0;
        }
      }
    }
  }
  .search-categories{
    border: 1px solid rgba(#fff, 0.2);
    border-radius: 0 0 3px 3px;
    position: absolute;
    width: 100%;
    left: 0;
    top: remy(48px);
    z-index: 100;
    box-shadow: 0 5px remy(10px) rgba($dark, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: $transition-base;
    background: #fff;
    ul{
      margin: 0;
      li{
        a{
          color: $text-grey1;
          padding: remy(10px) remy(15px);
          display: block;
          span{
            width: remy(34px);
            height: remy(34px);
            border-radius: remy(300px);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: remy(8px);
            color: #fff;
          }
        }
        &:hover{
          background: #f5f7fc;
        }
      }
    }
  }
}

.top-menu-area .d_menu {
  padding-left: 35px;
}

.top-menu-area .top__menu ul li {
  display: inline-block;
  a{
    color: #555;
  }
}

.author-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.author__access_area {
  padding: 14px 20px 14px 35px;
  ul{
    margin:0;
    li {
      margin-right: remy(30px);
      &:last-child {
        margin-right: 0;
      }
      .access-link{
        &:hover{
          @extend .color-primary;
        }
      }
      & > span{
        display: inline-block;
        padding: 0 4px;
      }
      .author-info{
        position: relative;
        &:after{
          position: absolute;
          content: '';
          height: 20px;
          width: 100%;
          background: transparent;
          left: 0;
          bottom: -20px;
        }
        .author-avatar{
          display: block;
        }
        &:hover{
          ul{
            visibility: visible;
            opacity: 1;
          }
        }
        ul{
          background: #fff;
          box-shadow: 0 -3px remy(30px) rgba($dark, 0.1);
          position: absolute;
          right: 0;
          min-width: remy(200px);
          top: remy(70px);
          padding:remy(15px) 0;
          visibility: hidden;
          opacity: 0;
          z-index: 4;
          transition: $transition-base;
          &:before{
            position: absolute;
            content: '';
            border-bottom: 13px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: -13px;
            right: remy(15px);
          }
          li{
            margin: 0;
            padding: 0 remy(10px);
            a{
              display: block;
              color: $text-grey1;
              padding: remy(7px) remy(15px);
              border-radius: 3px;
              &:hover{
                color: #fff;
                @extend .bg-secondary;
              }
            }
          }
        }
      }
    }
  }
}


/* start mainenu area css */
.mainmenu__menu {
  border: none;
  margin: 0;
  padding: 0;
  .navbar-collapse {
    padding: 0;
    float: left;
  }
}

.mainmenu__menu .navbar-nav > li {
  display: inline-block;
  padding: 17px 0;
  margin-right: 30px;
  &:hover {
    > a {
      @extend .color-primary;
    }
  }
  > a {
    font-weight: 500;
    padding: 0;
    font-size: 16px;
    line-height: 42px;
    transition: $transition-base;
    text-transform: capitalize;
  }
}

/* offcanvas menu */
.offcanvas-menu{
  position: relative;
  .offcanvas-menu__user{
    @extend .bg-secondary;
    color: #fff;
    padding: remy(13px);
    display: flex;
    font-size: remy(20px);
    margin-right: remy(30px);
    border-radius: 2px;
  }
  .offcanvas-menu__contents{
    &.active{
      right: -10px;
      transition: $transition-base;
    }
    position: fixed;
    height: 100%;
    width: 220px;
    top: 0;
    right: -250px;
    background: #fff;
    z-index: 989999;
    overflow-y: scroll;
    transition: $transition-base;
    box-shadow: 0 -2px 20px rgba($dark, 0.08);
    padding: remy(25px);
    .offcanvas-menu__close{
      position: absolute;
      left: remy(15px);
      top: remy(15px);
      font-size: remy(22px);
      color: $text-grey2;
    }
    .author-avatar{
      text-align: center;
      border-bottom: 1px solid $border-color;
      padding: remy(15px) 0;
      margin-bottom: remy(15px);
    }
    ul{
      li{
        a{
          display: block;
          padding: 8px remy(10px);
          border-radius: 3px;
          color: $text-grey1;
          &:hover{
            @extend .bg-secondary;
            color: #fff;
          }
        }
      }
    }
    .search_area{
      margin-top: remy(20px);
      text-align: center;
      .btn{
        margin-top: remy(10px);
      }
    }
  }
}


/*****************************
	END MENU AREA CSS
*****************************/
